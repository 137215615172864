import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { mdiCog } from '@mdi/js';
import Icon from '@mdi/react';

const CustomIconButton = ({ icon, buttonContent, menuItems, tooltipTitle, right }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', gap: "10px" }} >
                {buttonContent}

                <Tooltip title={tooltipTitle} placement='right' arrow >
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? 'custom-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        {/* {icon} */}
                        {icon ? (
                            icon
                        ) : (
                            <Icon path={mdiCog} size={"20px"} color={"white"} />
                        )}

                    </IconButton>
                </Tooltip>

            </Box>
            <Menu
                anchorEl={anchorEl}
                id="custom-menu"
                open={open}
                onClose={handleClose}
                //onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            // right: 52,
                            right: { right },
                            // left: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {menuItems.map((item, index) => {
                    // const Elem = item;
                    return <div key={index}>
                        {item.divider ? (
                            <Divider />
                        ) : (
                            item)}
                    </div>
                }
                )
                }
            </Menu>
        </React.Fragment>
    );
};

CustomIconButton.propTypes = {
    buttonContent: PropTypes.node.isRequired,
    menuItems: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.node.isRequired,
            onClick: PropTypes.func,
            icon: PropTypes.node,
            divider: PropTypes.bool,
        })
    ).isRequired,
    tooltipTitle: PropTypes.string,
    avatarText: PropTypes.string.isRequired,
};

// CustomIconButton.defaultProps = {
//     tooltipTitle: 'Menu',
// };

export default CustomIconButton;
