import React, { useState, useRef, Fragment } from "react";
import { Button, Paper, Dialog, DialogActions, DialogContent, TextField, Typography, IconButton } from "@mui/material";
import Draggable from 'react-draggable';
import { paperPropsSmaller } from '../style/muiStyle';
import axios from 'axios'
import '../style/login.css'
import '../style/global.css'
import MuiDialogTitle from '@mui/material/DialogTitle';
import Iconn from '@mdi/react';
import { mdiCloseCircle, mdiLock, mdiLockReset } from "@mdi/js";
import $ from "jquery";


const DialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <MuiDialogTitle  {...other}>
      <Typography >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" onClick={onClose}>
          <Iconn path={mdiCloseCircle} color="white" size="20px" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};


//Add a new role
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function ForgotPwd(props) {
  const [open, setOpen] = useState(false);
  const [nom, setNom] = useState(props.email);
  const [error, setError] = useState(false);
  const [msg, setMsg] = useState(false);
  const param = props.param

  $(document).ready(function () {
    $('#tel-edit').on('input', function (ev) {
      var $this = $(this);
      var value = $this.val();
      if (value && value.length > 10) {
        $this.val(value.substr(0, 10));
      }
    })
  });

  const handleClickOpen = () => {
    setOpen(true); setMsg(false); setNom(props.email)
  };

  const handleClose = () => { setOpen(false); };

  const vidage = () => {
    setOpen(false)
    setNom('')
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (nom !== "") {
      await axios({
        method: 'POST',
        url: param.urlService + 'forgot.php',
        headers: { 'content-type': 'application/json', 'appID': param.appID },
        data: JSON.stringify({ login: nom })
      })
        //recuperation des donnees du service pour l'autorisation d'acces ou non 
        .then(response => {
          if (response.data.err) {
            alert(response.data.err)
            setOpen(false)
          } else {
            setMsg(response.data.jData)
            setTimeout(vidage, 5000);
          }
        })
        .catch(error => {
          alert(error);
        })
    } else {
      setError(true)
    }

  }

  // const testPhone = (e) => {
  //   const re = /^[0-9\b]+$/;
  //   if (e.target.value === '' || re.test(e.target.value)) {
  //     setNom(e.target.value)
  //   }
  // }


  const testInput = (e) => {
    const value = e.target.value;
    const phoneRegex = /^[0-9\b]+$/; // Pour valider les numéros de téléphone
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Pour valider les adresses e-mail

    if (value === '' || phoneRegex.test(value) || emailRegex.test(value)) {
      setNom(value);
    } else {
      setError(true);
    }
  };


  return (
    <Fragment >
      {  //<div onClick={()=>handleClickOpen()} id='login-pwd'>
        //<Iconn path={mdiKey} color="black" size="20px"/><span id="pwd-forgot" >Mot de passe oublié?</span>
      }

      <div id="forgotDiv" onClick={() => handleClickOpen()}><a id="forgot" /></div>
      <Dialog
        open={open}
        id='changePass'
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        PaperProps={paperPropsSmaller}
      >
        <DialogTitle >
          <div className='mdps'>
            <Iconn path={mdiLock} color="white" size="20px" />
            <label>Réinitialiser le mot de passe </label>
          </div>
        </DialogTitle>
        <form ref={useRef(null)} id='forgotPass' onSubmit={handleSubmit} >
          <DialogContent className="centered-container">
            {/* <TextField fullWidth //type="tel" 
              error={error} helperText={(error) ? "Veuillez saisir votre nom d'utilisateur!" : null}
              label='Login' id="tel-edit" name="iTel" value={nom} variant='outlined'
              onChange={(e) => { testPhone(e); setError(false) }} required autoFocus
            //inputProps={{ pattern: "[0][1-7][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]" }}
            /> */}
            <TextField
              fullWidth
              error={error}
              helperText={error ? "Veuillez saisir  une adresse e-mail valide!" : null}
              label='Login'
              name="iTel"
              value={nom}
              variant='outlined'
              // onChange={(e) => {
              //   testInput(e);
              //   setError(false);
              // }}
              onChange={e => setNom(e.target.value)}
              required
              autoFocus
            />




            <br />
            {(msg === true) ? <span id='sms-forgot'>Votre mot de passe a été changé, vous allez recevoir un email.</span> : null}

          </DialogContent>

          <DialogActions>
            <Button className='Button-submit' variant="contained" type='submit' form='forgotPass' color="primary" startIcon={<Iconn path={mdiLockReset} size={1} />}>Réinitialiser</Button>
            <Button
              variant="contained"
              onClick={handleClose}
              className="Button-close"
            >
              FERMER
            </Button>
          </DialogActions>

        </form>
      </Dialog>
    </Fragment>
  );
}
