import { ReactComponent as Cash } from "../img/icons/tresorerie.svg";
import { ReactComponent as Products } from "../img/icons/produit.svg";
import { ReactComponent as Prelevement } from "../img/icons/ooredoo.svg";
// import { ReactComponent as RC } from "../img/icons/registre commerce .svg";
import { ReactComponent as COD } from "../img/icons/grossiste.svg";
import { ReactComponent as R } from "../img/icons/retail.svg";
import { ReactComponent as Bu } from "../img/icons/bu.svg";
import { ReactComponent as Secteur } from "../img/icons/secteur.svg";
import { ReactComponent as Frns } from "../img/icons/fournisseur.svg";
import { ReactComponent as Stromi } from "../img/icons/storm strock.svg";
import { ReactComponent as Voucher } from "../img/icons/voucher fill.svg";
import { ReactComponent as Scratch } from "../img/icons/scratch.svg";
import { ReactComponent as Sim } from "../img/icons/sim.svg";
import { ReactComponent as Prv } from "../img/icons/prelevement2.svg";
import { ReactComponent as Stock } from "../img/icons/stock-01.svg";
import { ReactComponent as Chq } from "../img/icons/cheque bancaire-01.svg";
// import { ReactComponent as Vir } from "../img/icons/virement bancaire-01.svg";
import { ReactComponent as Vir } from "../img/icons/virement bancaire icon2.svg";
import { ReactComponent as Thermalpaper } from "../img/icons/thermo papr-01-01.svg";
import { ReactComponent as Charge } from "../img/icons/charge icon2.svg";
import { ReactComponent as Commandes } from "../img/icons/commande icon.svg";
import { ReactComponent as Cpt } from "../img/icons/compt bancaire icon2.svg";
import { ReactComponent as Creance } from "../img/icons/creance icon2.svg";
import { ReactComponent as Historique } from "../img/icons/historique icon2.svg";
import { ReactComponent as Rapp } from "../img/icons/rapprochement icon2.svg";
import { ReactComponent as Nrc } from "../img/icons/RC icon2.svg";
import { ReactComponent as Role } from "../img/icons/role icon2.svg";
import { ReactComponent as Trnsfr } from "../img/icons/transfr icon2.svg";
import { ReactComponent as Typchg } from "../img/icons/type de charge icon2 .svg";
import { ReactComponent as Dmp } from "../img/icons/demande de paiment 2.svg";
import { ReactComponent as Vrs } from "../img/icons/versements.svg";
import { ReactComponent as Pay } from "../img/icons/payment.svg";
import { ReactComponent as Fac } from "../img/icons/facture.svg";
import { ReactComponent as Trk } from "../img/icons/sim tracking.svg";

import React from "react";

export function GetIconName(menu) {
  switch (menu) {
    case "A PROPOS":
    case "A propos":
      return "mdiInformation";
    case "ENTREPRISES":
    case "Entreprises":
      return "mdiCity";
    case "Employés":
      return "mdiAccountTie";
    case "Contrats":
      return "mdiFileDocumentEdit";
    case "Postes":
      return "mdiBriefcase";
    case "Unités":
      return "mdiOfficeBuilding";
    case "Banques":
      return "mdiBank";
    case "Conventions":
      return "mdiHumanCapacityIncrease";
    case "Signataires":
      return "mdiDrawPen";
    case "ADMIN.":
      return "mdiSecurity";
    case "Export":
      return "mdiFileTableBoxMultiple";
    case "Privilèges":
      return "mdiStarCheck";
    case "Roles":
      return <Role style={{ width: "20px" }} fill="#fff" />;
    case "Sessions":
      return "mdiAccountClock";
    case "Utilisateurs":
      return "mdiAccountMultiple";
    case "Paramètres":
      return "mdiCogs";
    case "CLIENTS":
      return "mdiAccountGroup";
    case "RC":
      return <Nrc style={{ width: "20px" }} fill="#fff" />;
    case "COD":
      return <COD style={{ width: "20px" }} fill="#fff" />;
    case "Retail":
      return <R style={{ width: "20px" }} fill="#fff" />;
    case "Business Units":
      return <Bu style={{ width: "20px" }} fill="#fff" />;
    case "Secteurs":
      return <Secteur style={{ width: "20px" }} fill="#fff" />;
    case "Facturation":
      return <Fac style={{ width: "15px" }} fill="#fff" />;
    case "Stock":
      return <Stock style={{ width: "20px" }} fill="#fff" />;
    case "Portefeuille":
      return "mdiAccountTie";
    case "Créances":
      return <Creance style={{ width: "20px" }} fill="#fff" />;
    case "Historique":
      return <Historique style={{ width: "20px" }} fill="#fff" />;
    case "MONITORING":
      return "mdiMonitorDashboard";
    case "Lettres d'offre":
      return "mdiFilePercent";
    case "Lettres d'objectif":
      return "mdiBullseyeArrow";
    case "Prélèvements":
      return <Prv style={{ width: "20px" }} fill="#fff" />;
    case "Commandes":
      return <Commandes style={{ width: "20px" }} fill="#fff" />;
    case "STOCK":
      return "mdiWarehouse";
    case "Tracking":
      return <Trk style={{ width: "15px" }} fill="#fff" />;
    case "Produits":
      return <Products style={{ width: "20px" }} fill="#fff" />;
    case "VOUCHER":
      return <Voucher style={{ width: "20px" }} fill="#fff" />;
    case "Papier thermique":
      return (
        <Thermalpaper style={{ width: "20px", height: "20px" }} fill="#fff" />
      );
    case "Transferts":
      return <Trnsfr style={{ width: "20px" }} fill="#fff" />;

    case "TOPUP":
      return <Stromi style={{ width: "20px" }} fill="#fff" />;
    case "SCRATCH":
      return <Scratch style={{ width: "20px" }} fill="#fff" />;
    case "SIM":
      return <Sim style={{ width: "15px" }} fill="#fff" />;

    case "REFERENTIEL":
      return "mdiFolderFile";
    case "Fournisseurs":
      return <Frns style={{ width: "20px" }} fill="#fff" />;
    case "Type de charges":
      return <Typchg style={{ width: "20px" }} fill="#fff" />;
    case "Warehouses":
      return "mdiWarehouse";
    case "REPORTS":
      return "mdiChartBarStacked";
    case "TRESORERIE":
      return <Cash style={{ width: "20px" }} fill="#fff" />;
    case "Chèques":
      return <Chq style={{ width: "20px" }} fill="#fff" />;
    case "Virements":
      return <Vir style={{ width: "20px" }} fill="#fff" />;
    case "Versements":
      // return "mdiAccountCash";
      return <Vrs style={{ width: "20px" }} fill="#fff" />;
    case "Dem. paiements":
      // return "mdiCashClock";
      return <Dmp style={{ width: "20px" }} />;
    case "Paiements":
      // return "mdiCashMultiple";
      return <Pay style={{ width: "20px", height: "20px" }} fill="#fff" />;
    case "Rapprochements":
      return <Rapp style={{ width: "20px" }} fill="#fff" />;
    case "Comptes":
      return <Cpt style={{ width: "20px" }} fill="#fff" />;
    case "Charges":
      return <Charge style={{ width: "20px" }} fill="#fff" />;
    case "Dashboard":
      return "mdiViewDashboard";
    case "Historique":
      return "mdiClipboardTextClock";
    default:
      return " mdiAlertCircle";
  }
}
