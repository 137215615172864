import React, { useEffect, useState, useMemo } from "react";
import { Button, Tooltip, Typography } from "@mui/material";
import { Route, Switch, Redirect } from "react-router-dom";
import { ReactComponent as To } from "./../img/to.svg";
import { AlertProvider } from "../contexts/alertContext";
import Empt from "./empty";
import MainMenu from "./main_menu";
import ParamContext from "../contexts/paramContext";
import axios from "axios";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { mdiCog, mdiLockReset, mdiLogout } from "@mdi/js";
import Icon from "@mdi/react";
import UserProfile from "../components/UserProfile";
import AvatarCell from "../components/avatar";
import ResetPwd from "./changepass";
import CustomIconButton from "../components/menu_button";
import LogoResp from "./../img/logos/logo_iman_full.png";
import Balance from "../components/caisse"
export default function Pages({ param, menu, url }) {
  const [dynamicComponents, setDynamicComponents] = useState([]);
  const version = require("../version.json");
  const Gfunc = require("../Gfunc");
  const defaultPath = window.location.pathname;

  const menuItems = [
    <ResetPwd param={param} />,
  ];

  // Load dynamic components
  useEffect(() => {
    const loadDynamicComponents = async () => {
      const dynamicComponentsArray = await Promise.all(
        menu.flatMap((elem) =>
          elem.children.map(async (child) => {
            var module = null;
            try {
              module = await import(`./content/${child.link}`);
            } catch (e) {
              module = null;
            }
            return {
              parentId: elem.id,
              parent: elem.parent,
              itself: child.child,
              id: `/${elem.id}/${child.id}`,
              component: module ? module.default : null,
              description: child.description,
            };
          })
        )
      );
      setDynamicComponents(dynamicComponentsArray);
    };

    loadDynamicComponents();
  }, [menu]);

  // Memoize the result of getDynamicComponentForPath to avoid unnecessary recalculations
  const dynamicComponentForPath = useMemo(() => {
    var selectedCompo = dynamicComponents.find((obj) => obj.id === defaultPath);
    document.title = selectedCompo?.itself ? selectedCompo?.itself : selectedCompo?.parent ? selectedCompo?.parent : ("iMan " + version[0].build);
    return selectedCompo;
  }, [defaultPath, dynamicComponents]);

  // Logout function
  const Logout = () => {
    axios
      .get(`${param.urlService}disconnect.php`, { headers: Gfunc.getHeader() })
      .then((response) => {
        if (response.data.jData === true) {
          localStorage.removeItem("token");
          localStorage.setItem("isLoged", false);
          localStorage.clear();
          window.location = "/";
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ParamContext.Provider value={param}>
        <AlertProvider>
          {dynamicComponents.length > 0 && (
            <div className="content">
              <MainMenu param={param} menu={menu} />
              <div id="contenu">
                <div id="header">
                  {defaultPath && (
                    <div className="line-container">
                      <span className="pageTitleElem">
                        {dynamicComponentForPath?.parent}
                      </span>
                      <div className="horizontal-line"></div>
                      <span className="pageTitleElem">
                        {dynamicComponentForPath?.itself}
                      </span>
                      <div className="horizontal-line"></div>
                      <div className="pageTitleElemMain">
                        <span className="pageTitleElem">
                          {dynamicComponentForPath?.description}
                        </span>
                      </div>
                    </div>
                  )}

                  <div id="disconnect" >
                    <Balance />
                    <img
                      src={LogoResp}
                      alt="logo"
                      style={{ height: "45px", marginRight: "16px" }}
                    />
                  </div>


                </div>

                <div id="main-content">
                  <Switch>
                    <Route exact path="/">
                      <Redirect to={url} />
                    </Route>
                    {dynamicComponents.map((dynamicComponent, index) => (
                      <Route
                        key={index}
                        path={`${dynamicComponent.id}`}
                        render={(routeProps) => {
                          const DynamicComponent = dynamicComponent.component;
                          if (DynamicComponent)
                            return (
                              <DynamicComponent {...routeProps} param={param} />
                            );
                          else
                            return (
                              <Empt
                                message="Page introuvable"
                                hideButton={true}
                              />
                            );
                        }}
                      />
                    ))}
                    {/* Redirect to the first child if the dynamic component is not found */}
                    {dynamicComponentForPath === undefined &&
                      dynamicComponents.find(
                        (obj) =>
                          obj.parentId === defaultPath.replace(/^\/+/, "")
                      ) && (
                        <Redirect
                          to={`${defaultPath}/${menu.find(
                            (obj) =>
                              obj.id === defaultPath.replace(/^\/+/, "")
                          ).firstChild
                            }`}
                        />
                      )}
                    {/* Fallback route for page not found */}
                    <Route
                      render={() => (
                        <Empt message="Page introuvable" hideButton={true} />
                      )}
                    />
                  </Switch>
                </div>

                <div id="footer">
                  <span>
                    {param.webApp} {version[0].build} &copy; {param.copyright}
                  </span>
                  <span id="powered">
                    powered by {param.company} for {param.licence}
                  </span>
                  <span id="lastMAJ"> Last update: {version[0].date}</span>
                </div>
              </div>
            </div>
          )}
        </AlertProvider>
      </ParamContext.Provider>
    </LocalizationProvider >
  );
}
