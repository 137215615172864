import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";

export default function VerticalScrollableTabs({
  children,
  ornt = "vertical",
  style,
  fullWidth,
  handleTabChange,
  auto,
  height,
  indicatorWidth,
}) {
  // Destructure children from props
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (handleTabChange) handleTabChange(event, newValue);
  };

  return fullWidth === undefined ? (
    <Box
      sx={{
        maxWidth: { xs: 320, sm: 480 },
        // bgcolor: "transparent",
        display: "flex",
        height: height ? height : "100%",
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        orientation={ornt} // Change to vertical
        variant="scrollable"
        scrollButtons
        sx={{
          // borderRight: 1,
          borderColor: "divider",
          width: "100%",

          "& .MuiTabs-indicator": {
            backgroundColor: indicatorWidth ? "" : "transparent !important",
            // width: "140px !important",
            width: indicatorWidth ? indicatorWidth : "0px !important",
          },
          ...style,
        }} // Optional styling
      >
        {children} {/* Render children here */}
      </Tabs>
    </Box>
  ) : (
    <Tabs
      value={value}
      onChange={handleChange}
      orientation={ornt} // Change to vertical
      variant="scrollable"
      scrollButtons={auto ?? "scroll"}
      sx={{
        width: "100%",
        ...style,
      }} // Optional styling
    >
      {children} {/* Render children here */}
    </Tabs>
  );
}
