import React, { useEffect } from "react";
import { Tooltip, Typography } from "@mui/material";
import { ReactComponent as Reste } from "../img/icons/le reste.svg";

function Usr_Caisse() {
    const Gfunc = require("../Gfunc.js");


    useEffect(() => {

        Gfunc.fetchCaisse(localStorage.getItem("uid"));

    }, [Gfunc]);

    const handleCaisse = () => {
        Gfunc.fetchCaisse(localStorage.getItem("uid"));
    };

    return (
        <>

            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100%", cursor: "pointer" }} onClick={handleCaisse} >
                <Tooltip title="Balance" arrow disableInteractive>
                    <Reste style={{ width: "18px", height: "18px", fill: "#012244", marginTop: "4px", marginRight: "8px" }} /></Tooltip>
                <Typography id="userBalance" style={{ fontSize: "12px !important", color: "#012244" }}>
                </Typography>
            </div>

        </>
    );
}

export default React.memo(Usr_Caisse);
